label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  left: 0px;
  max-width: 350px !important;
  padding: 40px 40px;
}
header{
  padding-bottom: 1%;
  width: 100%;
  padding-top: 1%;
}
.footer{
  width: 110% !important;
  text-align:re;
  padding-left: 0;
}
.card {

  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.navbar{
  position: fixed !important;
  margin-top: 0%;
  top: 0;
  min-width: 100%;
  min-height: 5.3rem;
  z-index: 10;
  font-size: larger;
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.nav-item{
  display: flex;
  width: 100%;
}
.nav-link-t{
  color: rgb(88, 88, 88);
}
.jumbotronl{
  text-align: left;
  color: rgb(122, 122, 122);
  padding-left: 0;
  margin-left: 0;
}
.container{
  margin-left: 0;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 200%;
  
 
}
.text{
  color: rgb(122, 122, 122);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: large;
  width: 100%;
}
h2{
  color: rgb(92, 92, 92);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 25px;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 1%;
}
.textl{
  color: rgb(122, 122, 122);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  width: 100%;
  font-weight: 500;
}
.containerl{
  padding-top: 8%;
  padding-left: 0;
  margin-left: 0;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  min-width: fit-content;
}
.containerf{
  padding-top: 0%;
  padding-left: 1%;
  margin-left: 0;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  min-width: fit-content;
  width: 2000px;
}
ol{
  width: 100%;
}
.jumbotronl{
  width: 100%;
}
.mt-3{
  min-width: 100% !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  width: 150%;
  max-width: 200%;
}
.public-table{
  width: 100%;
  min-width: 90%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  text-align: left;
  border-collapse: collapse;
  border: 1px solid rgb(221, 221, 221);
  margin-left: 5%;
}
.rate-table{
  width: 100%;
  min-width: 90%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  text-align:re;
  border-collapse: collapse;
  border: 1px solid rgb(221, 221, 221);
  margin-left: 0%;
}
.hire-table{
  width: 100%;
  min-width: 90%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  text-align:re;
  border-collapse: collapse;
  border: 1px solid rgb(221, 221, 221);
  margin-left: 0%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.fst{
  color: rgb(122, 122, 122);
  font-weight: 700;
  width: 20%;
  border-bottom: 1px solid rgb(221, 221, 221);
  padding-left: 1%;
  font-size: large;
}
.sst{
  color: rgb(122, 122, 122);
  border-bottom: 1px solid rgb(221, 221, 221);
  padding: 1%;
  font-size: large;
}
.footer{
  
  width: 100%;
  margin-top: 3%;
  padding-left:15%;
  background-color: rgb(209, 209, 209);
  margin-bottom: 0;
  padding-top: 1%;
  padding-bottom: 1%;
  color: rgb(122, 122, 122);;
}
.textRate{
  color: rgb(122, 122, 122);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 25px;
  border-left: 1px solid rgb(221, 221, 221);
  border-bottom: 1px solid rgb(221, 221, 221);
  padding: 1%;
}
th{
  background-color: rgb(243, 243, 243);
  font-size: larger;
  color: rgb(122, 122, 122);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 1%;
}
.button{
  margin-top: 5%;
  height: 4%;
  color: aliceblue;
  background-color: #4b67a0;
  padding-bottom: 0.2%;
}
.buttonX{
  margin-top: 3%;
  height: 4%;
  width: 18%;
  color: aliceblue;
  background-color: #4b67a0;
  padding-bottom: 0.2%;
  font-size: x-large;
}
.buttonY{
  margin-top: 5%;
  height: 4%;
  width: 7%;
  color: aliceblue;
  background-color: #4b67a0;
  padding-bottom: 0.2%;
  font-size: x-large;
}
.buttonDel{
  margin-top: 5%;
  margin-left: 1%;
  height: 3%;
  color: aliceblue;
  background-color: #4b67a0;
  padding-bottom: 0.2%;
}
.searchInput{
  padding-left: 0.3%;
  padding-top: 0.3%;
  padding-bottom: 0.3%;
  margin-left: 1%;
  margin-right: 1%;
  font-size: large;
  margin-top: 5%;
  margin-bottom: 1%;
  height: 3%;
}
.table-header{
  background-color: #4b67a0;
  color: aliceblue;
  width: fit-content;
  font-size: large;
}
.title{
  margin-top: 2%;
  margin-left: 1%;
}
.titleDamage{
  font-size: medium;
  margin-top: 0.5%;
  margin-left: 1%;
}
nav{
  position:fixed;
  height:60px;}

.flex{
  margin-top: 0%;
  width: 100%;
  display: flex;
}
.flex1{
  margin-top: 0%;
  width: 100%;
  display: flex;
}
.flexAssets{
  margin-top: 1%;
  width: 100%;
  display: flex;
}
.inlineLnI{
  margin-left: 1%;
  margin-bottom: 1%;
  width: 100%;
  font-size:x-large;
  font-weight: 700;
  color:  rgb(122, 122, 122);
}
.inlineLnA{
  margin-top: 1%;
  margin-left: 1%;
  width: 49%;
  font-size:x-large;
  font-weight: 700;
  color:  rgb(122, 122, 122);
}
.inlineLnx{
  margin-left: 0%;
  margin-top: 1.5%;
  width: 99%;
  font-size:x-large;
  font-weight: 700;
  color:  rgb(122, 122, 122);
}

.stationIn{
  margin-left: 1%;
  width: 100%;
  font-size:x-large;
  font-weight: 700;
  color:  rgb(97, 97, 97);
}
.inputStat{
  width: 100%;
  border-color: rgb(212, 212, 212);
  border-radius: 5px;
  padding-left: 1%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(97, 97, 97);
}
.twoIn1{
  width: 100%;
  border-color: rgb(212, 212, 212);
  border-radius: 5px;
  padding-left: 2%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(97, 97, 97);
}
.twoIn2{
  width: 100%;
  border-color: rgb(212, 212, 212);
  border-radius: 5px;
  padding-left: 2%;
  margin-left: 2%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(97, 97, 97);
}
.twoIn3{
  width: 100%;
  border-color: rgb(212, 212, 212);
  border-radius: 5px;
  padding-left: 1%;
  margin-left: 2%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(97, 97, 97);
}
.customerName{
  font-size: x-large;
  padding-left: 1%;
  font-weight: 700;
  color:  rgb(122, 122, 122);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.subtt{
  font-size: xx-large;
  padding-bottom: 2%;
  font-weight: 700;
  color:  rgb(122, 122, 122);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  padding-top: 1%;
  border-top: 1px solid rgb(194, 194, 194);
}
.subttt{
  font-size: xx-large;
  padding-bottom: 2%;
  margin-top: 2%;
  font-weight: 700;
  color:  rgb(122, 122, 122);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  padding-top: 1%;
  border-top: 1px solid rgb(194, 194, 194);
}
.subttV{
  font-size: xx-large;
  margin-top: 5%;
  padding-bottom: 2%;
  border-top: 1px solid rgb(194, 194, 194);
  font-weight: 700;
  color:  rgb(122, 122, 122);
  width: 100%;
  padding-top: 1%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.req{
  font-size: medium;
  color: rgb(214, 109, 109);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.collectionTitle{
  margin-bottom: 3%;
}
.eCorrection{
  margin-left: 2%;
}
label{
  color:   rgb(122, 122, 122);
}
.calendar{
  margin-left: 1%;
  margin-top: 3%;
}
.timeDisplay{
  margin-top: 3%;
  margin-left: 2%;
  overflow-y: scroll;
  min-width: 10%;
  height: 300px;
}
.summary{
  color:  rgb(122, 122, 122);
  margin-left: 2%;
  
}
.summaryBox{
  border: 1px solid rgb(187, 187, 187);
  border-radius: 5px;
  width:100%;
  margin-left: 5%;
  padding-left: 2%;
}
.summaryTd{
  padding-left: 3%;
  font-size: large;
  border-bottom: 1px solid rgb(190, 190, 190);
}
.summaryTable{
  margin-left: 4%;
}
.summaryFoot{
  padding-top: 2%;
}
.summaryF{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(122, 122, 122);
  font-size: x-large;
}
.summaryTh{
  padding-left: 2%;
  min-width: 200px;
}
.submitButton{
  margin-left: 59%;
  margin-top: 8%;
  font-size: xx-large;
  border-radius: 5px;
  color: aliceblue;
  background-color: #4b67a0;

}
.submitButtonn{
  
  color: aliceblue;
  background-color: #4b67a0;
  border-radius: 5px;

}
.submitButtonnAss{
  
  color: aliceblue;
  background-color: #4b67a0;
  border-radius: 5px;

}
.submitButtonAss{
  text-align: right;
  margin-top: 5%;
  font-size: xx-large;
  border-radius: 5px;


}
.thankForm{
  padding-top: 5%;
  text-align:re;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(122, 122, 122);
}
.react-calendar__navigation__label {
  font-weight: bold;
  font-size: large;
}
.react-calendar__navigation__arrow {
  flex-grow: 0.333;
}
.react-calendar__month-view__weekdays {
  text-align: left;
  color:#838383;
}
.react-calendar__tile--range {
  box-shadow: 0 0 6px 2px black;
}
.react-calendar__navigation {
  display: flex;
}
  
.react-calendar__navigation__label {
  font-weight: bold;
}
  .react-calendar__navigation__arrow {
    flex-grow: 0.333;
  }

.react-calendar{
  font-size: large;
  min-width: 700px !important;
}
.container.mt-3{
  padding-left: 1%;
  padding-top: 0%;
  width: fit-content !important;
}
.react-datepicker-wrapper{
  margin-left: 2%;
}
.availableOffTitle{
  text-align: right;
  padding-top: 4.5%;
  padding-left: 60%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(122, 122, 122);
}
.availableOffTitleC{
  text-align: right;
  padding-top: 5.5%;
  padding-left: 65%;
  margin-left: 10%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(122, 122, 122);
}
.titleCorrection{
 margin-left: 0% !important;
}
.titleCorrectionR{
  margin-left: 9% !important;
}
.titleCorrectionMore{
  margin-left: 0% !important;
}
.correctUn{
  margin-top: 3.4%;
  margin-left: 2%; 
}
.correctUn2{
  margin-top: 0%;
  margin-left: 2%; 
}
.availableOffTitle2{
  padding-top: 5%;
  padding-left: 64%;
}
.availableOffTitle3{
  padding-top: 5%;
  padding-left: 29%;
}
.assetsOutcome{
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(122, 122, 122);
  text-align:re;
  padding-top: 5%;
}
.renderCell{
  overflow: visible;
  
}
MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone{
  max-height: fit-content!important;
  }
  .MuiDataGrid-cell{
  max-height:fit-content!important;
  overflow:auto;
  max-height: inherit;
  white-space: initial!important;
  line-height:16px!important;
  display:flex!important;
  align-items:re;
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  >div{
  max-height: inherit;
  width: 100%;
  white-space: initial;
  line-height:16px;
  }
  }
  .pdfview{
    min-width: 100%;
    min-height: 1500px;
    height: 100%;
    max-height: 100%;
  }
  .texta{
    height: 400px;
  }
  .attach{
    margin-left: 5%;
  }
  .flexo{
    display: flex;
    text-align: right;
    margin-left: 75%;
    padding-top: 5%; 
  }
  .spaceing{
    min-width: 4%;
  }
  .scanner{
    padding-left: 3%;
  }
  .multipleLines{
    margin-left: 2%;
    width: 100%;
    border-color:  rgb(122, 122, 122);;
    padding: 1%;
    border-radius: 5px;
  }
  .pictures{
    text-align:re;
  }
  .textPicture{
    min-width: 100%;
    margin-left: 0.7%;
    min-height: 25%;
    font-size: 55px;
    margin-top: 2%;
    margin-bottom: 12%;
    padding-left: 1%;
  }
  .buttonPictures{
    width: 5%;
    min-height: 15%;
    font-size: 40px;
    margin-bottom: 2%;
    margin-right: 4%;
    border-radius: 5px;
    color: white;
    background-color: #4b67a0;
  }
  .buttonS{
    width: 10%;
    min-height: 15%;
    font-size: 30px;
    margin-bottom: 2%;
    margin-right: 2%;
    margin-left: 15%;
    border-radius: 5px;
    color: white;
    background-color: #4b67a0;
  }
  .buttonSS{
    width: 10%;
    min-height: 15%;
    font-size: 30px;
    margin-bottom: 2%;
    margin-right: 2%;
    margin-left: 15%;
    border-radius: 5px;
    color: white;
    background-color: #4b67a0;
  }
  .imageFeed{
    max-height: 85%;
    max-width: 85%;
    padding-top: 3%;
  }
  .picturesC{
    text-align:re;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
  }
  .toggle{
    margin-left: 3%;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .typogarphy{
    font-size: 60px;
  }
  .offline{
    background-color: rgb(250, 133, 133);
    width: 100%;
    margin: 0;
    text-align:re;
    border-radius: 5px;
  }
  .online{
    background-color: rgb(0, 194, 162);
    width: 100%;
    margin: 0;
    text-align:re;
    border-radius: 5px;
  }
  .cameraTitle{
    padding-left: 1%;
    padding-top: 2.5%;
  }
  .selectDamage{
    padding-right: 2.5%;
  }
  .inline{
    display: inline;
  }
  .shoot{
    font-size: x-large;
    margin-left: 6%;
    background-color: #4b67a0;
    min-height: 45px;
  }
  .between{
    width: 82%;
  }
  .between1{
    width: 80%;
  }
  .between2{
    width: 74%;
  }
  .countShoots{
    margin-top: 5.8%;
    margin-left: 1%;
    min-width: 30%;
  }
  .wait{
    min-width: 10%;
  }
  .waitEle{
    margin-top: 2%;
    min-width: 10%;
  }
  .pdfviewcamera{
    min-width: 2000px;
    min-height: 2000px;
  }
  .space{
    margin-left: 0.5%;
  }
  .spaceUp{
    margin-top: 3%;
  }
  .wide{
    text-align:re;
  }
  .refresh{
    color: rgb(119, 199, 143);
  }
  .sigCanvas{
    border: 1px solid rgb(129, 129, 129);
  }
  .flexX{
    display: flex;
    text-align:re;
    padding-left: 30%;
    width: 100%;
  }
  .desp{
    width: 18%;
  }
  .titlel{
    margin-top: 0.8%;
    padding-right: 1%;
  }
  .circular{
    width: 100%;
    text-align:re;
    margin-top: 30%;
    margin-bottom: 28%;
  }
  .cor{
    margin-left: 0%;
    max-width: 99%;
  }
  .allign1{
    text-align: right;
    margin-top: 10%;
    margin-bottom: 20%;
  }
  .flexProfile{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    background-color: #e2e2e2;
    padding: 2%;
    width: 104%;
    margin-left: 1%;
  }
  .andi{
    width: 20px;
    margin-left: 31%;
  }
  .vehiPic{
    max-width: 30%;
    height: 40%;
    margin-top: 4%;
  }
  .marginPic{
    margin-top: 2%;
    margin-left: 2%;
    margin-right: 2%;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid rgb(201, 201, 201);
    background-color: #ebebeb;
    border-radius: 5px;
    padding-top: 1%;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 2%;
  }
  .vehiPicDet{
    width: 100%;
    
  }
  .profileTitle{
    margin-bottom: 0%;
    margin-left: 79%;
    padding-right: 2%;
  }
  .profileDiv{
    width: 80%;
  }
  .subttX{
    font-size: xx-large;
    /*margin-top: 1%;*/
    /*padding-bottom: 1%;*/
    /*border-top: 1px solid rgb(194, 194, 194);*/
    font-weight: 600;
    border: 200px;
    color:  rgb(122, 122, 122);
    width: 45%;
    padding-top: 1%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: right;
    border-bottom: 1px solid  rgb(184, 184, 184);
  }
  .subttXX{
    font-size: xx-large;
    /*margin-top: 1%;*/
    /*padding-bottom: 1%;*/
    /*border-top: 1px solid rgb(194, 194, 194);*/
    font-weight: 600;
    border: 200px;
    color:  rgb(122, 122, 122);
    width: 45%;
    padding-top: 1%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: right;
    border-bottom: 1px solid  rgb(184, 184, 184);
  }
  .subttT{
    font-size: x-large;
    font-weight: 400;
    color:  rgb(122, 122, 122);
    width: 200px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
  }
  .subttTt{
    font-size: x-large;
    font-weight: 400;
    color:  rgb(122, 122, 122);
    width: 200px ;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
  }
  .tableProfile{
    width: 50% !important;
  }
  .tableProfileD{
    width: 50% !important;
    border-left: 1px solid rgb(184, 184, 184);
    margin-left: 1%;
  }
  .centre{
    text-align:re;
    margin-left: 2%;
    min-width: 500px;
    border: 1px solid rgb(165, 165, 165);
    border-radius:  5px;
    background-color: #ebebeb;
  }
  .div.MuiTypography-root.MuiTypography-body1.MuiTimelineOppositeContent-root.MuiTimelineOppositeContent-positionRight.css-n2itgq-MuiTypography-root-MuiTimelineOppositeContent-root{
    width: 400%;
    padding: 0% !important;
  }
  .buttonP{
    margin-top: 0%;
    height: 4%;
    width: 100px;
    color: aliceblue;
    background-color: #4b67a0;
    padding-bottom: 0.2%;
  }
  .buttonPP{
    margin-top: 0%;
    height: 4%;
    width: 100px;
    color: aliceblue;
    background-color: #4ba060;
    padding-bottom: 0.2%;
  }
  .buttonPPP{
    margin-top: 0%;
    height: 4%;
    width: 100px;
    color: aliceblue;
    background-color: #a04b4b;
    padding-bottom: 0.2%;
  }
  .pdfviewP{
    min-width: 68.3%;
    min-height: 1480px;
  }
  .calendarOff{
    margin-left: 2%;
  }
  .radio{
    margin-left: 4%;
  }
  .MuiFormControlLabel-label{
    font-size: xx-large;
  }
  .buttonOff{
    margin-top: 1%;
    height: 9%;
    border-radius: 5px;
    font-size: 36px;
    color: aliceblue;
    background-color: #4b67a0;
    padding-bottom: 0.2%;
  }
  .unhidden{
    display: none;
  }
  .sigCanvas{
    width: 900px;
    height: 500px;
  }
  .calendarX{
    margin-left: 2%;
  }
  .buttonM{
    margin-top: 5%;
    margin-left: 1%;
    max-height: 40px;
    width: 10% !important;
    color: aliceblue;
    background-color: #4b67a0;
    padding-bottom: 0.2%;
    font-size: x-large;
  }
  .exx{
    min-width: 200px;
  }
  .dec{
    font-size: 22px;
  }
  .titleZ{
    font-weight: 600;
    margin-top: 1%;
    border-bottom: 1px solid rgb(165, 165, 165);
  }
  .subtitleL{
    margin-top: 1%;
  }
  .submitButtonAssX{
    text-align: right;
    margin-top: 5%;
    font-size: xx-large;
    border-radius: 5px;
    background-color: gray;
  
  }
  .logo{
    width: 100%;
    margin-right: 0px !important;
  }
  .navbar-brand{
    padding-right: 10px !important;
    width: 10rem;
  }
  .containerB{
    padding-left: 1%;
    margin-left: 0;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 1600px;
    height: 500px;
    background-color: rgb(216, 216, 216);
    border-radius: 5px;
  }
  .pictureFrame{
    text-align:re;
    padding-left: 0px !important;
    max-width: 1000px;
  }
  .css-f70jqn{
    text-align: center;
    margin-left: 700px !important;
    width: fit-content;
  }
 .picture{
  width: 500px;
 }
 .submitButtonnAssL{
  
  color: aliceblue;
  background-color: #929292;
  border-radius: 5px;

}
.userTable{
  max-width: 30%;
  min-width: 15%;
  width: 30%;
  font-size: larger;
  border-bottom: 1px solid white;
  padding-left: 5%;
}
.reqUser{
  font-size: large;
  padding-top: 5%;
  color: #a04b4b;
}
.correctionX{
  margin-left: 1%;
}
.MuiFormControlLabel-label {
  font-size: x-large !important;
}
.submitPay{
  margin-left: 1.5%;
}
  @media only screen and (max-width: 600px){
    
    .userTable{
    min-width: 40%;
      width: 50%;
      font-size: larger;
      border-bottom: 1px solid white;
      padding-left: 5%;
    }
    .containerB{
      padding-left: 1%;
      margin-left: 0;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      width: 550px;
      height: 500px;
      background-color: rgb(216, 216, 216);
      border-radius: 5px;
    }
    .buttonPictures{
      width: 100%;
      min-height: 15%;
      font-size: 30px;
      margin-bottom: 2%;
      margin-right: 0.5%;
      border-radius: 5px;
      color: white;
      background-color: #4b67a0;
    }
    .css-f70jqn{
      text-align: center;
      margin-top: 10px !important;
      margin-left: 10px !important;
      width: fit-content;
    }
    .buttonDelete{
        margin-top: 15%;
        height: 4%;
        color: aliceblue;
        background-color: #4b67a0;
        padding-bottom: 0.2%;
    }
    .css-b62m3t-container{
      margin-top: 5%;
      width: 110%;
      border-color:  rgb(122, 122, 122);;
    }
    .css-1d51lpo-menu{
      font-size: 90%;
    }
    .hide{
      display: none;
    }
    .navbar{
      margin-top: -3% !important;
      z-index: 10;
      width: 100%;
      min-width: 0px;
    }
    .footer{
      width: 100% !important;
      text-align: center;
      padding-left: 0;
      
    }
    .containerf{
      padding-top: 8%;
      padding-left: 0;
      margin-left: 0;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      min-width: fit-content;
      width: 100%;
    }
    .unhidden{
      display: contents;
    }
   
    .react-calendar{
      font-size: large;
      min-width: 510px !important;
    }
    .flex{
      display: contents;
    }
    .css-1fou3jl-control{
      width: 500px !important;
      min-width: 0px !important;
    }
    .css-11gcjel-control{
      width: 500px !important;
      min-width: 0px !important;
      height: 10%;
    }
    .css-1zi0xd-control{
      width: 500px !important;
      min-width: 0px !important;
      margin-left: 15px !important;
    }
    .correctionFieldsMobile{
      margin-left: 11px;
      width: 500px !important;
    }
    .correctionFieldsMobile2{
      width: 500px !important;
    }
    .correctionFieldsMobile3{
      margin-left: 5px;
      width: 500px !important;
    }
    .correctionFieldsMobile4{
      margin-left: 0px;
      width: 500px !important;
    }
    .correctionFieldsMobile5{
      margin-left: 15px !important;
      width: 500px !important;
    }
    .correctionFieldsMobile6{
      margin-left: 20px;
      width: 500px !important;
    }
    .correctionFieldsMobile7{
      margin-left: 15px;
      width: 500px !important;
    }
    .react-calendar__month-view__weekdays{
      font-size: 20px !important;
    }
    .MuiButtonBase-root{
      font-size: 20px !important;
      width: 500px !important;
    }
    .timeDisplay{
      text-align: center;
    }
    .summaryBox{
      margin-left: 8px !important;
      margin-top: 15px !important;
    }
    .summaryTable{
      margin-top: 5px;
      margin-left: 0px;
    }
    .correctionReg{
      min-height: 38px !important;
    }
    .submitButtonn{
      width: 100% !important;
    }
    .flexAssets{
      display: contents;
    }
    .scanner{
      width: 550px !important;
      padding-left: 5px !important;
    }
    .flexo{
      display: flex;
      text-align: right;
      margin-left: 15px !important;
      padding-top: 5%; 
    }
    .check{
      width: 10px !important;
      margin-right: 5px !important;
      padding-right: 20px !important;
    }
    .buttonY{
      margin-top: 5%;
      height: 4%;
      width: 100% !important;
      color: aliceblue;
      background-color: #4b67a0;
      padding-bottom: 0.2%;
      font-size: x-large;
    }
    .allign1{
      text-align: right;
      margin-top: 10%;
      margin-bottom: 20%;
      width: 100%;
    }
    .sigCanvas{
      width: 500px !important;
      height: 300px !important;
    }
    .sig{
      width: 500px !important;
      height: 300px !important;
    }
    .inlineLnx{
      padding: 0% !important;
      margin: 0% !important;
      width: 500px !important;
    }
    .navbar{
      font-size: 20px !important;
    }
    .nav-link-t{
      font-size:  26px !important;
    }
    .flexProfile{
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: #e2e2e2;
      padding: 2%;
      width: 100%;
      margin-left: 1%;
      margin-top: 10px !important;
      margin-right: 50px !important;
    }
    .MuiCardMedia-img{
      height: 140px !important;
      width: 210px !important;
      margin-right: 10px !important;
    }
    .andi{
      margin-left: 0px !important;
    }
    .searchInput{
      height: 50px !important;
      margin-top: 10px !important;
    }
    .button{
      height: 45px !important;
      width: 45px;
      margin-top: 0px !important;
      margin-bottom: 20px;
      margin-left: 10px !important;
    }
    .buttonM{
      margin-top: 5%;
      margin-left: 1%;
      height: 60px !important;
      width: 30% !important;
      color: aliceblue;
      background-color: #4b67a0;
      padding-bottom: 0.2%;
      font-size: x-large;
    }
    .dec{
      font-size: 22px;
    }
    .titleZ{
      font-weight: 600;
      margin-top: 3%;
      border-bottom: 1px solid rgb(165, 165, 165);
    }
    .subtitleL{
      margin-top: 3%;
    }
    .buttonP{
      margin-bottom: 5%;
      margin-left: 25%;
      height: 50%;
      width: 100px;
      color: aliceblue;
      background-color: #4b67a0;
      padding-bottom: 0.2%;
    }
    .buttonPP{
      margin-bottom: 5%;
      margin-left: 25%;
      height: 50%;
      width: 100px;
      color: aliceblue;
      background-color: #4ba052;
      padding-bottom: 0.2%;
    }
    .buttonPPP{
      margin-bottom: 5%;
      margin-left: 25%;
      height: 50%;
      width: 100px;
      color: aliceblue;
      background-color: #a04b4b;
      padding-bottom: 0.2%;
    }
    .centre{
      text-align: center;
      margin-left: 2%;
      min-width: 400px;
      border: 1px solid rgb(165, 165, 165);
      border-radius:  5px;
      background-color: #ebebeb;
    }
    .buttonSS{
      width: 18%;
      min-height: 15%;
      font-size: 30px;
      margin-bottom: 2%;
      margin-right: 3%;
      margin-left: 0%;
      border-radius: 5px;
      color: white;
      background-color: #4b67a0;
    }
    .vehiPic{
      max-width: 100%;
      height: 50%;
      margin-top: 4%;
    }
    .subttX{
      font-size: x-large;
      /*margin-top: 1%;*/
      /*padding-bottom: 1%;*/
      /*border-top: 1px solid rgb(194, 194, 194);*/
      font-weight: 600;
      border: 200px;
      color:  rgb(122, 122, 122);
      width: 45%;
      padding-top: 1%;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      text-align: left;
      border-bottom: 1px solid  rgb(184, 184, 184);
    }
    .subttXX{
      font-size: x-large;
      padding-left: 0%;
      font-weight: 600;
      border: 200px;
      color:  rgb(122, 122, 122);
      width: 45%;
      padding-top: 1%;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      text-align: left;
      border-bottom: 1px solid  rgb(184, 184, 184);
    }
    .tableProfileD{
      margin-left: 0%;
      border-left: 0px !important;
      width: 450px !important;
      text-align: left;
    }
    .tableProfile{
      margin-left: 0%;
      border-left: 0px !important;
      width: 450px !important;
      text-align: left;
    }
    td{
      width: 50%;
      margin-left: 15% !important;
      text-align: left;
    }
    tr{
      width: 100% !important;
    }
    .navbar-brand{
      padding-right: 10px !important;
      width: 15rem !important;
    }
    .correctionX{
      margin-left: 2% !important;
    }
    .mobileContainerCorrection{
      padding-top: 13% !important;
    }
    .formPay {
      width: 80vw;
      min-width: initial;
      margin-left: 50px;
    }
    .wrapperPayment{
      display: contents;
      background-color: rgb(216, 216, 216) !important;
      padding-left: 40px !important;
      border-radius: 5px !important;
    }
    .flexProfile{}
    .pics{
      width: 70% !important;
      margin-right: 2%;
      margin-left: 1% !important;
      margin-top: 5%;
    }
    .pics2{
      width: 60% !important;
      margin-right: 0% !important;
      margin-left: 19% !important;
      
    }
    .mobileCor{
      padding-left: 15% !important;
      padding-top: 5%;
    }
    .line{
      border-top: 1px solid rgb(143, 143, 143) !important;
    }
    .correcting{
      display: initial !important;
      margin-left: 10% !important;
      margin-right: 15% !important;
      margin-top: 20% !important;
      padding-top: 0% !important;
      border-top: 1px rgb(143, 143, 143) !important;
      color: rgb(112, 112, 112);
    }
    .correcting1{
      padding-left: 15% !important;
      color: rgb(112, 112, 112);
      border-top: 1px solid rgb(143, 143, 143) !important;
    }
    .titleForm{
      padding-top: 10% !important;
      margin-left: 2%;
      color: gray;
    }
    .videoPlayer{
      padding-top: 10% !important;
    }
    .vUpload{
      padding-top: 10% !important;
    }
    .loginAsk{
      padding-top: 10% !important;
      color: #a04b4b;
      padding-left: 3%;
    }
    .jAinRj{
      margin-top: 40% !important;
     }
  }
  
  .formPay {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
    margin-left: 100px;
  }
  
  
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  /* Buttons and links */
  .buttonPay {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  .buttonPay:hover {
    filter: contrast(115%);
  }
  
  .buttonPay:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  .wrapperPayment{
    display: flex;
    background-color: rgb(216, 216, 216);
    padding-left: 40px;
    border-radius: 5px;
  }
  .titleC{
    font-size: larger;
    color: rgb(82, 82, 82);
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
 
  .payConfirmation{
    background-color: #80bb8f;
    width: 2200px;
    height: 200px;
    text-align: center;
    padding-top: 70px;
    color: #505050;
  }
  .tableX{
    width: 300% !important;
    font-size: larger;
    margin-top: 2%;
  }
  .legend{
    margin-top: 5%;
    color: rgb(231, 75, 75);
    font-size: larger;
  }

  .doneB{
    cursor: pointer;
  }
  .videoUpload{
    width: 100%;
  }
  .reg{
    width: 100%;
    font-size: x-large;
    min-height: 15% ;
    padding: 0.3%;
    margin: 1%;
    font-weight: 1000;
    font-family: Arial, Helvetica, sans-serif;
  }
  .regLabel{
    font-size: 30px;
    padding-left: 40%;
  }
  #hide{
    width: 60%;
  }
 .vUpload{
  text-align: center;
  margin-right: 35%;
 }
 .flexAssetsY{
  display: flex;
  padding-left: 30%;
  margin-top: 3%;
 }
 .bitMargin{
  margin-left: 10%;
 }
 .videoPlayer{
  margin-left: 10px;
  width: 550px !important;
  height: 800px;
 }
 .jAinRj{
  margin-top: 50%;
 }
 .fZioFw{
  margin-top: 50% !important;
 }
.videoCorrection{
  padding-top: 5%;
}
.containerfCorrection{
  padding-top: 3%;
}
.containerfCorrectionB{
  padding-top: 15%;
}

.availableOffTitleB{
  text-align: right;
  padding-top: 2.7%;
  padding-left: 23%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(122, 122, 122);
  
  min-width: 300px !important;
}
.flexB{
  display: flex;
  width: 200%;
  padding-top: -1% !important;
}
.searchInputB {
  padding-left: 0.3%;
  padding-top: 0.3%;
  padding-bottom: 0.3%;
  margin-left: 1%;
  margin-right: 1%;
  font-size: large;
  margin-top: 2.5%;
  margin-bottom: 0.5%;
  height: 1%;
}
.access{
  width: 30%;
  margin-left: 2%;
  margin-top: 0;
  padding-top: 4% !important;
}
.containerhCorrection{
  padding-top: 15%;
  padding-right: 15%;
}
.jumbotronH{
  height: 60% !important;
  width: 105% !important;
  text-align: right;
}
.white{
  color: white;
  font-weight: 700;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.mainTit{
  font-size: xx-large;
  font-weight: 700;
}
.pics{
  width: 45%;
  margin-right: 2%;
  margin-left: 10%;
}
.assetsOutcome{}
.pics2{
  width: 85%;
  margin-right: 2%;
  margin-left: 1%;
}
.correcting{
  display: flex;
  margin-left: 15%;
  margin-right: 20%;
  margin-top: 4%;
  padding-top: 1%;
  border-top: 1px solid rgb(221, 221, 221);
  color: rgb(112, 112, 112);
}
.correcting1{
  padding-left: 10%;
  color: rgb(112, 112, 112);
}
.faq{
  margin-top: 2%;
  padding-top: 3%;
  padding-left: 7%;
 
}
.correctionCamera{
  margin-top: 5%;
}
.flexCam{
  display: flex;
  width: 95%;
}
.fnr{
  margin-top: 5%;
  margin-left: 2%;
}
.corVideoForm{
  margin-top: -5%;
}
.bitMarginF{
  margin-left: 5%;
  width: 90%;
}
.titleForm{
  padding-top: 5%;
  margin-left: 2%;
  color: gray;
}
.flexFormF{
  display: flex;
  margin-left: 100px;
}
.loginAsk{
  padding-top: 5%;
  color: #a04b4b;
  padding-left: 3%;
}
.subttT{
  font-size: xx-large;
  margin-top: 1%;
  padding-bottom: 2%;
  border-bottom: 1px solid rgb(194, 194, 194);
  font-weight: 700;
  color:  rgb(122, 122, 122);
  width: 100%;
  padding-top: 1%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.twoIn4{
  width: 100%;
  border-color: rgb(212, 212, 212);
  border-radius: 5px;
  padding-left: 2%;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color:  rgb(97, 97, 97);
}
.correctionT{
  margin-top: 5%;
}
.inlineLnT{
  width: 100%;
  margin-left: 0%;
  margin-top: 1.5%;
  font-size:x-large;
  font-weight: 700;
  color:  rgb(122, 122, 122);
}
.bitMarginY{
  margin-top: 5%;
  margin-left: 1%;
  width: 100%;
}
.buttonVin{
  background-color: #4b67a0;

  width: 100%;
  margin-left: 1%;
  margin-top: 2%;
  font-size: xx-large;
  height: 3%;
}
.buttonVid{
  background-color: #4b67a0;
  color: white;
  height: 30px;
}
.container{
  padding-right: 0px !important;
}
.subttTT{
  font-size: x-large;
  font-weight: 600;
  color:  rgb(122, 122, 122);
  width: 500px !important;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: left;
}
.bold{
  font-weight: 700 !important;
}
@media only screen and (max-width: 700px){
    
  .userTable{
  min-width: 40%;
    width: 50%;
    font-size: larger;
    border-bottom: 1px solid white;
    padding-left: 5%;
  }
  .containerB{
    padding-left: 1%;
    margin-left: 0;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 550px;
    height: 500px;
    background-color: rgb(216, 216, 216);
    border-radius: 5px;
  }
  .buttonPictures{
    width: 100%;
    min-height: 15%;
    font-size: 30px;
    margin-bottom: 2%;
    margin-right: 0.5%;
    border-radius: 5px;
    color: white;
    background-color: #4b67a0;
  }
  .css-f70jqn{
    text-align: center;
    margin-top: 10px !important;
    margin-left: 10px !important;
    width: fit-content;
  }
  .buttonDelete{
      margin-top: 15%;
      height: 4%;
      color: aliceblue;
      background-color: #4b67a0;
      padding-bottom: 0.2%;
  }
  .css-b62m3t-container{
    margin-top: 5%;
    width: 110%;
    border-color:  rgb(122, 122, 122);;
  }
  .css-1d51lpo-menu{
    font-size: 90%;
  }
  .hide{
    display: none;
  }
  .navbar{
    margin-top: 0% !important;
    z-index: 10;
    width: 100%;
    min-width: 0px;
  }
  .footer{
    width: 100% !important;
    text-align: center;
    padding-left: 0;
    
  }
  .containerf{
    padding-top: 9%;
    padding-left: 0;
    margin-left: 0;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    min-width: fit-content;
    width: 100%;
  }
  .unhidden{
    display: contents;
  }
 
  .react-calendar{
    font-size: large;
    min-width: 510px !important;
  }
  .flex{
    display: contents;
  }
  .css-1fou3jl-control{
    width: 500px !important;
    min-width: 0px !important;
  }
  .css-11gcjel-control{
    width: 500px !important;
    min-width: 0px !important;
    height: 10%;
  }
  .css-1zi0xd-control{
    width: 500px !important;
    min-width: 0px !important;
    margin-left: 15px !important;
  }
  .correctionFieldsMobile{
    margin-left: 11px;
    width: 500px !important;
  }
  .correctionFieldsMobile2{
    width: 500px !important;
  }
  .correctionFieldsMobile3{
    margin-left: 5px;
    width: 500px !important;
  }
  .correctionFieldsMobile4{
    margin-left: 0px;
    width: 500px !important;
  }
  .correctionFieldsMobile5{
    margin-left: 15px !important;
    width: 500px !important;
  }
  .correctionFieldsMobile6{
    margin-left: 20px;
    width: 500px !important;
  }
  .correctionFieldsMobile7{
    margin-left: 15px;
    width: 500px !important;
  }
  .react-calendar__month-view__weekdays{
    font-size: 20px !important;
  }
  .MuiButtonBase-root{
    font-size: 20px !important;
    width: 500px !important;
  }
  .timeDisplay{
    text-align: center;
  }
  .summaryBox{
    margin-left: 8px !important;
    margin-top: 15px !important;
  }
  .summaryTable{
    margin-top: 5px;
    margin-left: 0px;
  }
  .correctionReg{
    min-height: 38px !important;
  }
  .submitButtonn{
    width: 100% !important;
  }
  .flexAssets{
    display: contents;
  }
  .scanner{
    width: 550px !important;
    padding-left: 5px !important;
  }
  .flexo{
    display: flex;
    text-align: right;
    margin-left: 15px !important;
    padding-top: 5%; 
  }
  .check{
    width: 10px !important;
    margin-right: 5px !important;
    padding-right: 20px !important;
  }
  .buttonY{
    margin-top: 5%;
    height: 4%;
    width: 100% !important;
    color: aliceblue;
    background-color: #4b67a0;
    padding-bottom: 0.2%;
    font-size: x-large;
  }
  .allign1{
    text-align: right;
    margin-top: 10%;
    margin-bottom: 20%;
    width: 100%;
  }
  .sigCanvas{
    width: 500px !important;
    height: 300px !important;
  }
  .inlineLnx{
    padding: 0% !important;
    margin: 0% !important;
    width: 500px !important;
  }
  .navbar{
    font-size: 20px !important;
  }
  .nav-link-t{
    font-size:  26px !important;
  }
  .flexProfile{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #e2e2e2;
    padding: 2%;
    width: 100%;
    margin-left: 1%;
    margin-top: 10px !important;
    margin-right: 50px !important;
  }
  .MuiCardMedia-img{
    height: 140px !important;
    width: 210px !important;
    margin-right: 10px !important;
  }
  .andi{
    margin-left: 0px !important;
  }
  .searchInput{
    height: 50px !important;
    margin-top: 10px !important;
  }
  .button{
    height: 45px !important;
    width: 45px;
    margin-top: 0px !important;
    margin-bottom: 20px;
    margin-left: 10px !important;
  }
  .buttonM{
    margin-top: 5%;
    margin-left: 1%;
    height: 60px !important;
    width: 30% !important;
    color: aliceblue;
    background-color: #4b67a0;
    padding-bottom: 0.2%;
    font-size: x-large;
  }
  .dec{
    font-size: 22px;
  }
  .titleZ{
    font-weight: 600;
    margin-top: 3%;
    border-bottom: 1px solid rgb(165, 165, 165);
  }
  .subtitleL{
    margin-top: 3%;
  }
  .buttonP{
    margin-bottom: 5%;
    margin-left: 25%;
    height: 50%;
    width: 100px;
    color: aliceblue;
    background-color: #4b67a0;
    padding-bottom: 0.2%;
  }
  .buttonPP{
    margin-bottom: 5%;
    margin-left: 25%;
    height: 50%;
    width: 100px;
    color: aliceblue;
    background-color: #4ba052;
    padding-bottom: 0.2%;
  }
  .buttonPPP{
    margin-bottom: 5%;
    margin-left: 25%;
    height: 50%;
    width: 100px;
    color: aliceblue;
    background-color: #a04b4b;
    padding-bottom: 0.2%;
  }
  .centre{
    text-align: center;
    margin-left: 2%;
    min-width: 400px;
    border: 1px solid rgb(165, 165, 165);
    border-radius:  5px;
    background-color: #ebebeb;
  }
  .buttonSS{
    width: 18%;
    min-height: 15%;
    font-size: 30px;
    margin-bottom: 2%;
    margin-right: 3%;
    margin-left: 0%;
    border-radius: 5px;
    color: white;
    background-color: #4b67a0;
  }
  .vehiPic{
    max-width: 100%;
    height: 50%;
    margin-top: 4%;
  }
  .subttX{
    font-size: x-large;
    /*margin-top: 1%;*/
    /*padding-bottom: 1%;*/
    /*border-top: 1px solid rgb(194, 194, 194);*/
    font-weight: 600;
    border: 200px;
    color:  rgb(122, 122, 122);
    width: 45%;
    padding-top: 1%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
    border-bottom: 1px solid  rgb(184, 184, 184);
  }
  .subttXX{
    font-size: x-large;
    padding-left: 0%;
    font-weight: 600;
    border: 200px;
    color:  rgb(122, 122, 122);
    width: 45%;
    padding-top: 1%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;
    border-bottom: 1px solid  rgb(184, 184, 184);
  }
  .tableProfileD{
    margin-left: 0%;
    border-left: 0px !important;
    width: 450px !important;
    text-align: left;
  }
  .tableProfile{
    margin-left: 0%;
    border-left: 0px !important;
    width: 450px !important;
    text-align: left;
  }
  td{
    width: 50%;
    margin-left: 15% !important;
    text-align: left;
  }
  tr{
    width: 100% !important;
  }
  .navbar-brand{
    padding-right: 10px !important;
    width: 15rem !important;
  }
  .correctionX{
    margin-left: 2% !important;
  }
  .mobileContainerCorrection{
    padding-top: 13% !important;
  }
  .formPay {
    width: 80vw;
    min-width: initial;
    margin-left: 50px;
  }
  .wrapperPayment{
    display: contents;
    background-color: rgb(216, 216, 216) !important;
    padding-left: 40px !important;
    border-radius: 5px !important;
  }
  .flexProfile{}
  .pics{
    width: 70% !important;
    margin-right: 2%;
    margin-left: 1% !important;
    margin-top: 5%;
  }
  .pics2{
    width: 60% !important;
    margin-right: 0% !important;
    margin-left: 19% !important;
    
  }
  .mobileCor{
    padding-left: 15% !important;
    padding-top: 5%;
  }
  .line{
    border-top: 1px solid rgb(143, 143, 143) !important;
  }
  .correcting{
    display: initial !important;
    margin-left: 10% !important;
    margin-right: 15% !important;
    margin-top: 20% !important;
    padding-top: 0% !important;
    border-top: 1px rgb(143, 143, 143) !important;
    color: rgb(112, 112, 112);
  }
  .correcting1{
    padding-left: 15% !important;
    color: rgb(112, 112, 112);
    border-top: 1px solid rgb(143, 143, 143) !important;
  }
  .titleForm{
    padding-top: 10% !important;
    margin-left: 2%;
    color: gray;
  }
  .videoPlayer{
    padding-top: 10% !important;
  }
  .vUpload{
    padding-top: 10% !important;
  }
  .loginAsk{
    padding-top: 10% !important;
    color: #a04b4b;
    padding-left: 3%;
  }
  .jAinRj{
    margin-top: 40% !important;
   }
}
@media only screen and (max-width: 1400px) and (min-width: 700px){
  .flex{
    display: contents;
  }
  .containerf{
    max-width: 65% !important;
    min-width: fit-content;
  }
  .inlineLnI{
    max-width: 65% !important;
    
  }
  .calendar{
    padding-left: 20%;
    font-size: xx-large;
  }
  .react-calendar{
    font-size: 30px;
    min-width: 700px !important;
  }
  .react-calendar__month-view__weekdays{
    font-size: 1em !important;
  }
  .MuiButtonBase-root{
    margin-left: 45% !important;
  }
 
  .buttonPictures{
    width: 100%;
  }
  .unhidden{
    display: contents;
  }
  .hide{
    display: none;
  }
  .calendarCorrection{
    padding-left: 0% !important;
  }
  .navbar{
    font-size: x-large;
  }
  .dropdown-menu{
    font-size: xx-large;
  }
  .inlineCorrection{
    display: flex;
  }
  .timeDisplay{
    width: 20% !important;
    height: 100% !important;
  }
  .MuiToggleButtonGroup-root{
    width: 100% !important;
    font-size: x-large;
  }
  .flexo{
    margin-left: 38% !important;
  }
  .flexoCorrection{
    margin-left: 60% !important;
  }
  .submitButtonAss{
    text-align: left;
  }
  .css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root{
    padding-left: 0% !important;
    margin-left: 0% !important;
  }
  .flexProfile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background-color: #e2e2e2;
    padding: 2%;
    width: 100%;
    margin-left: 1%;
  }
  .buttonP{
    height: fit-content;
  }
  .buttonPPP{
    height: fit-content;
  }
  .buttonPP{
    height: fit-content;
  }
  .centre{
    max-width: 1275px !important;
  }
  .flexAssetsC{
    display: contents !important;
  }
  .dot {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
}
.dot {
  margin-left: -8px;
  margin-right: 10px;
  height: 18px;
  width: 18px;
  background-color: #666666;
  border-radius: 50%;
  display: inline-block;
  position: sticky;
}
.sig{
  border: 1px dashed gray;
  width: 20%;
  height: 50%;
}